//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Cookies from "~/components/Cookies";

export default {
    name: "default",
    components: {
        Cookies
    },
    head() {
        this.$store.commit("set_cookie_day_scans", {count: +this.$cookies.get("day-scans") || 0});
        this.$store.commit("set_cookie_already_subscribed", {status: !!+this.$cookies.get("already-subscribed")});
        this.$store.commit("set_cookie_confirmed", {confirmed: !!+this.$cookies.get("confirmed")});
        return {
            meta: [
                {property: "og:url", content: `https://loading.express${this.$route.path}`}
            ],
            link: [
                {rel: "canonical", href: `https://loading.express${this.$route.path}`}
            ]
        }
    },
    mounted() {
        if (process.browser) {
          if (!window.requestIdleCallback) {
            window.requestIdleCallback = (func, options) => {
              options = options || {};
              setTimeout(func, options.timeout || 1);
            }
          }
          let psLoadedJs = false
          const getExtJs = () => {
            if (!psLoadedJs) {
              psLoadedJs = true
              window.removeEventListener("scroll", getExtJs, false)
              window.removeEventListener("touchstart", getExtJs, false)
              window.removeEventListener("mousemove", getExtJs, false)
              window.removeEventListener("click", getExtJs, false)
              window.removeEventListener("keydown", getExtJs, false)
              clearTimeout(getExtJsTimeout)
              requestIdleCallback(()=>{
                const YM = document.createElement("script");
                YM.innerHTML = "(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})(window, document, \"script\", \"https://mc.yandex.ru/metrika/tag_jet_beta.js\", \"ym\");ym(50208046, \"init\",{clickmap:true,trackLinks:true,accurateTrackBounce:true,ecommerce:\"dataLayer\"});";
                document.body.appendChild(YM);
              })
              requestIdleCallback(()=> {
                const GTM = document.createElement("script");
                GTM.innerHTML = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-MVK48MJ');";
                document.body.appendChild(GTM);
              })
            }
          }
          window.addEventListener("scroll", getExtJs, { passive: true })
          window.addEventListener("touchstart", getExtJs, { passive: true })
          window.addEventListener("mousemove", getExtJs, { passive: true })
          window.addEventListener("click", getExtJs, { passive: true })
          window.addEventListener("keydown", getExtJs, { passive: true })
          let getExtJsTimeout = setTimeout(getExtJs, 7000)
        }
    }
}
