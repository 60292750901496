// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/spinner-dark.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/images/sky-bottom.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".order-popup .form.loading{position:relative}.order-popup .form.loading:before{content:\"\";background:#fff;position:absolute;top:0;left:0;width:100%;height:100%;opacity:.7;z-index:1}.order-popup .form.loading:after{content:\"\";background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;background-size:contain;width:30px;height:30px;top:50%;left:50%;margin:-15px 0 0 -15px;position:absolute;z-index:2;animation:rotate 1s infinite}.order-popup .form .fz-block{margin-bottom:10px;margin-top:10px}.order-popup .form .form-item{width:100%;max-width:400px;display:block;border:1px solid #d9dee1;box-sizing:border-box;height:40px;padding:10px 20px;line-height:20px;margin:0 auto 10px;outline:none;font-size:18px}.order-popup .form .form-item:focus{border-color:#01a0e2}.order-popup .success .image{width:260px;height:260px;overflow:hidden;border-radius:50%;margin:10px auto 0;background:#1b2638 url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-size:600px;background-position:50%;position:relative}.order-popup .success .image .rocket{position:absolute;left:50%;top:30px;width:110px;margin-left:-55px;z-index:3}.order-popup .success .image .cloud{position:absolute}.order-popup .success .image .cloud.front{bottom:0;left:0;width:550px;z-index:4}.order-popup .success .image .cloud.middle{bottom:0;left:10px;width:480px;z-index:2}.order-popup .success .image .cloud.back{bottom:0;left:-20px;width:400px;z-index:1}@media screen and (min-width:1020px){.order-popup .success .image{width:450px;height:450px;background-size:800px;margin-top:20px}.order-popup .success .image .rocket{width:200px;top:55px;margin-left:-100px}.order-popup .success .image .cloud.front{width:800px;left:20px}.order-popup .success .image .cloud.middle{width:700px;left:50px;bottom:20px}.order-popup .success .image .cloud.back{width:550px;bottom:20px}}@keyframes rotate{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
